<template>
  <ModernModalLayout
    :title="`${isCreate ? 'Загрузить новый' : 'Редактировать'} файл помощи`"
    size="large"
  >
    <v-text-field
      v-if="isCreate"
      v-model="key"
      label="Ключ"
      :error-messages="getValidationErrors('key')"
      @blur="validateField('key')"
    />
    <v-text-field
      v-model="name"
      label="Название файла"
      class="mb-3"
      :error-messages="getValidationErrors('name')"
      @blur="validateField('name')"
    />
    <UploaderBox
      v-if="!pdf"
      accept="application/pdf"
      :size-limit="104857600"
      :error-messages="error"
      @change="changeFile"
      @fileSizeErr="fileSizeErr"
      @errorCheckPdfSignature="errorCheckPdfSignature"
      @rejectedFiles="rejectedFiles"
    >
      <template #description>
        <p class="mt-3 text-center" style="line-height: 1.6">
          Нажмите на кнопку или
          <br />
          перенесите PDF файл в эту область.
          <br />
          Максимальный размер файла 100 MB.
        </p>
      </template>
    </UploaderBox>

    <div v-if="fileAsUrl">
      <iframe
        :src="fileAsUrl"
        type="application/pdf"
        style="object-fit: contain"
        width="100%"
        height="500px"
      />
      <p v-if="error" class="error--text">{{ error.join('; ') }}</p>
    </div>

    <template #actions>
      <v-spacer />
      <v-btn
        v-if="!isCreate"
        :loading="$wait('upload')"
        color="error"
        text
        @click="remove"
      >
        Удалить
      </v-btn>
      <v-btn :loading="$wait('upload')" color="primary" text @click="upload">
        {{ isCreate ? 'Загрузить' : 'Сохранить' }}
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import waitable from '@/utils/mixins/waitable';
import { mapActions } from 'vuex';
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators';
import { uniqueStringKey } from '@/utils/validators';
import validationMixin from '@/utils/validation';

// components go below
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import UploaderBox from '@/components/controls/UploaderBox.vue';

export default {
  components: { ModernModalLayout, UploaderBox },
  mixins: [validationMixin, waitable],

  props: {
    oldKey: { type: String, required: false, default: null },
    oldName: { type: String, required: false, default: '' },
  },

  data() {
    return {
      pdf: null,
      error: null,
      key: this.oldKey,
      name: this.oldName,
    };
  },

  validations() {
    return {
      key: {
        required: requiredIf(() => this.isCreate),
        uniqueStringKey,
      },
      name: { required, maxSymbolsLength: maxLength(45) },
      pdf: { required: requiredIf(() => this.isCreate) },
    };
  },

  computed: {
    isCreate() {
      return !this.oldKey;
    },
    fileAsUrl() {
      return this.pdf ? URL.createObjectURL(this.pdf) : null;
    },
  },

  methods: {
    ...mapActions('STRUCTURES', [
      'createHelpFile',
      'uploadHelpFile',
      'removeHelpFile',
    ]),
    rejectedFiles(files) {
      if (files.some(file => file.type !== 'application/pdf')) {
        this.$notify({
          group: 'note',
          type: 'error',
          title: 'Ошибка загрузки файла',
          text: 'Данный формат не поддерживается. Загрузите файл в формате PDF',
        });
      }
    },
    changeFile(file) {
      this.error = null;
      this.pdf = file;
    },
    async upload() {
      this.error = null;
      this.$v.$touch();
      if (this.$v.$invalid) {
        if (!this.pdf) {
          this.error = ['Обязательно'];
        }
        return;
      }

      this.$loadingNotify(
        this.isCreate
          ? this.createHelpFile({
              key: this.key,
              name: this.name,
              pdf: this.pdf,
            })
          : this.uploadHelpFile({
              key: this.key,
              name: this.name,
              pdf: this.pdf,
            }),
        'upload',
        `Произошла ошибка ${
          this.isCreate ? 'загрузки' : 'изменения'
        } файла помощи`,
        this.isCreate
          ? 'Новый файл помощи успешно загружен'
          : 'Файл помощи отредактирован',
      )
        .then(() => this.$emit('close', { action: 'upload' }))
        .catch(err => this.catchServerErrors(err, ''));
    },
    async remove() {
      this.$loadingNotify(
        this.removeHelpFile({ key: this.key }),
        'upload',
        'Произошла ошибка удаления файла помощи',
        'Файл помощи успешно удален',
      ).then(() => this.$emit('close', { action: 'remove' }));
    },

    fileSizeErr(file) {
      this.$notify({
        group: 'note',
        type: 'error',
        title: 'Превышен допустимый размер файла',
        text: `Слишком большой файл! (${Math.floor(
          file.size / 1048576,
        )}Мб) Загрузите файл не больше 100MB`,
      });
    },

    errorCheckPdfSignature() {
      this.$notify({
        group: 'note',
        type: 'error',
        title: 'Ошибка целостности файла',
        text: `Файл поврежден`,
      });
    },
  },
};
</script>
